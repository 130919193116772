import { find } from "../utils/dom";

const Contato = () =>{
    const PageContato = find(`[js-pages='Contato']`);
    if (!PageContato) return;
    
    const ButtonInputFile = find('[js-input-file]');
    const InputFile = find(`input[name="curriculum"]`);
    const FileMessage = find('[js-file-message]');

    const Observer = new MutationObserver((mutations)=>{

        /** Pegando o diretório fake */
        const fakeDir = mutations[0].target.value
        
        /** Fazendo manipulação de string para retornar só o nome do arquivo e extensão */
        const NameFile = fakeDir.replace(`C:\\fakepath\\`, "");

        FileMessage.classList.remove('hidden')
        const ResultMessage = FileMessage.querySelector('[js-file-message-confirm]');
        ResultMessage.innerText = `${NameFile} adicionado!`;
    })


    Observer.observe(InputFile, {attributes: true, childList: true, characterData: true })

    /*if(ButtonInputFile){
        ButtonInputFile.addEventListener("click", (e)=>{
            const InputFile = find(`input[name="curriculum"]`);
            console.log(InputFile)
        })
    }*/
}

export default Contato;