import { find } from "utils/dom"

// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { gsap } from 'gsap'

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	const swiper = find("[js-swiper='home']");

	const swiperParams = {
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		  },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		on: {
			init: (e) => {
				const { slides, realIndex, previousIndex } = e;
				const el = slides[realIndex].querySelector("img");
				anime(el, 80);


				const Prev = find(".swiper-button-prev");
				const Next = find(".swiper-button-next");

				Prev.addEventListener("click", ()=>{
					e.slidePrev();
				})
				Next.addEventListener("click", ()=>{
					e.slideNext();
				})
			},
			activeIndexChange: function (e) {
				const { slides, realIndex, previousIndex } = e;

				let x = 80;

				if (Math.random() > 0.5) x = 80;
				else x = -80;

				if (previousIndex > realIndex) {
					x = -80;
				}

				const el = slides[realIndex].querySelector("img");
				anime(el, x, slides);
			}

		}

	}

	function anime(el, x, els) {
		const isMobile = window.matchMedia("(min-width: 768px)").matches;

		gsap.set(el, {
			scale: 1.15,
			x: isMobile ? x : 0,
		});
		// if (els) els.forEach(i => gsap.set(i, {}))
		gsap.timeline().to(el, {
			clearProps: "all",
			scale: 1.1,
			x: 0,
			duration: 15,
			ease: "power1.out",
			overwrite: true,
			//   transformOrigin: "right left",
		});
	}


	Object.assign(swiper, swiperParams)
	swiper.initialize();



}