import axios from 'axios'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

export default function contatoForm() {
	const showToastify = (message, opts) => {
		Toastify({
			duration: 3000,
			position: 'center',
			style: { background: 'rgba(193, 255, 129)', fontSize: '18px', color: 'rgba(45, 71, 120, 1)' },
			text: message,
			...opts,
		}).showToast()
	}

	const btnFormContato = document.querySelectorAll('.btn-contact')
	const formContato = document.querySelector('#send_contact')

	if (btnFormContato) {
    btnFormContato.forEach((btn) => {
        btn.addEventListener('click', function(event) {
            event.preventDefault();
            disabledBtn();

            const campoNome = document.querySelector("#name");
            const campoEMail = document.querySelector("#email");
            const campoMenssagem = document.querySelector("#message");

            const arrayCampos = [
                campoNome,
                campoEMail,
                campoMenssagem
            ];

            let camposVazios = false;

            arrayCampos.forEach((campo) => {
                if (campo.value.trim() === "") {
                    campo.classList.add("border-red-500"); 
                    camposVazios = true;
                } else {
                    campo.classList.remove("border-red-500"); 
                }
            });

            if (camposVazios) {
                alert("Preencha todos os campos");
                return;
            }

            const formData = new FormData(formContato);

            console.log(formData);

            const url = '/fale-conosco/';
            axios
                .post(url, formData)
                .then((response) => showMessage(response))
                .catch((response) => showMessage(response))
        });
    });
}


const formInputs = document.querySelectorAll("input, textarea");
formInputs.forEach(function(input) {
    input.addEventListener("input", function() {
        if (input.value.trim() !== "") {
            input.classList.remove("border-red-500");
        }
    });
});

	function showMessage(response) {
		const isSuccess = response.data && response.data.message

		const message = isSuccess ? 'Mensagem enviada com sucesso! Obrigado!' : 'Ops, algo deu errado! Tente novamente!'

		const backgroundColor = isSuccess ? 'rgba(193, 255, 129)' : 'rgba(233, 44, 44)'
		const textColor = isSuccess ? 'rgba(45, 71, 120, 1)' : 'white'

		showToastify(message, {
			style: {
				background: backgroundColor,
				color: textColor,
				fontSize: '18px',
				borderRadius: '8px',
			},
		})
		formContato.reset()
		setTimeout(enabledBtn, 1000)
	}

	function disabledBtn() {
		btnFormContato.forEach((btn) => {
			btn.disabled = true
			btn.classList.add('disabled')
		})
	}

	function enabledBtn() {
		btnFormContato.forEach((btn) => {
			btn.disabled = false
			btn.classList.remove('disabled')
		})
	}
}
