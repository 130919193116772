// import gsap from 'gsap'
import { find, findAll } from '../utils/dom'

const projetos = () => {
	const page_projetos = find("[js-page='projetos']")

	if (!page_projetos) return




		// function carregarImagem(url) {
		// 			console.log(url)
		// }
		

	// const Layout_1 = find("[js-layout-grid='1']");
	// const Layout_2 = find("[js-layout-grid='2']");



	//Inicializar o Layout 2 invisível.
	//Layout_2.classList.add("hidden");

	// Pegando a url da página
	const parsedUrl = new URL(window.location.href)

	//Fazendo comparação se a url possui um endereço de pesquisa (search)
	//if(parsedUrl.search != ''){
	// Layout_2.classList.remove("hidden");
	//Layout_1.classList.add("hidden");
	// }


}

export default projetos
