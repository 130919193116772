import { find } from "../utils/dom"

//Função troca de logos
const Switch_Logo = (Is_Switch, Logo_Black, Logo_White) => {
    if (Is_Switch === true) {
        Logo_Black.classList.remove("Logo-Hidden")
        Logo_White.classList.add("Logo-Hidden")
    }
    else if (Is_Switch === false) {
        Logo_Black.classList.add("Logo-Hidden")
        Logo_White.classList.remove("Logo-Hidden")
    }
}


const Value_Scroll = (condition) => {
    return (document.documentElement.scrollTop > condition || document.body.scrollTop > condition);
}

const Reset_Logo_Page = (idPage) => {

    // Páginas que devem possuir logo branco.
    const list_PagesId = ["Home", "Quem_somos"];


    return (list_PagesId.includes(idPage));

}


// Animação do collapso
const Collapse_Menu = (is_animated, Menu) => {

    const mql = window.matchMedia("(min-width: 767px)");

    if (mql.matches) {
        if (is_animated == true && !document.body.classList.contains("menu_isOpen")) {
            Menu.style.maxHeight = 0;
            Menu.style.transform = "translateY(-30px)";
        }
        else {
            Menu.style.maxHeight = Menu.scrollHeight + "px";
            Menu.style.transform = "translateY(0)";
        }
    } else {
        Menu.style.maxHeight = "100vh";
        Menu.style.transform = "translateY(0)";
    }
}

const Menu = () => {

    //Page
    const Page = find("[js-pages]");


    const Menu_Options_Mobile = find("[js-menu-option-mobile]");

    const Nav_Header = find("[js-nav-header]");

    //Logos
    const Logo_Black = find("[js-logo-black]");
    const Logo_White = find("[js-logo-white]");

    const Menu_Logo = find("[js-page='home'] [js-logo='principal']");



    const Menu = find("[js-menu]");
    const Menu_Hamburgue = find("[js-menu-hamburgue]");

    if (!Reset_Logo_Page(Page.id)) {
        document.body.classList.add("text-second-color")
    }
    

    if(Menu_Logo){
        Menu_Logo.addEventListener("click", (e)=>{
            e.preventDefault();

            if (e.target.classList.contains("active")) {
                e.target.classList.remove("active");
                Menu.classList.remove("active");

                document.body.classList.remove("menu_isOpen");
                document.body.classList.add("menu_isClose");

                Collapse_Menu(true, Menu)

            }
            else {
                e.target.classList.add("active");
                Menu.classList.add("active");

                document.body.classList.add("menu_isOpen");
                document.body.classList.remove("menu_isClose");

                Collapse_Menu(false, Menu)

            }
        })
        //Menu_Logo.preventDefault;
    }


    // Tratando os menus
    if (Menu_Hamburgue && Menu) {
        Menu_Hamburgue.addEventListener("click", (e) => {
            if (e.target.classList.contains("active")) {
                e.target.classList.remove("active");
                Menu.classList.remove("active");

                document.body.classList.remove("menu_isOpen");
                document.body.classList.add("menu_isClose");

                Collapse_Menu(true, Menu)

            }
            else {
                e.target.classList.add("active");
                Menu.classList.add("active");

                document.body.classList.add("menu_isOpen");
                document.body.classList.remove("menu_isClose");

                Collapse_Menu(false, Menu)

            }
        })
    }


    if (Nav_Header) {

        //Evento ao scrollar
        document.addEventListener("scroll", (e) => {
            if (Value_Scroll(142)) {
                Nav_Header.classList.add("Fixed");

                document.body.classList.add("is_Scroll")

                Switch_Logo(false, Logo_Black, Logo_White);
            }
            else {

                document.body.classList.remove("is_Scroll")
                Nav_Header.classList.remove("Fixed");
            }
        })


        //Evento ao executar um resize
        window.addEventListener("resize", (e) => {
            Collapse_Menu(true, Menu)
        })
    }




    // Tratando a navegação como toda
    /*if (Nav_Header) {
        document.addEventListener("scroll", (e) => {
            if (Value_Scroll(142)) {
                Nav_Header.classList.remove("relative");
                Nav_Header.classList.add("fixed-header");

                Switch_Logo(true, Logo_Black, Logo_White);

                if (Menu_Hamburgue.classList.contains("active")) {
                    Switch_Logo(false, Logo_Black, Logo_White);
                }


            }
            else {
                Nav_Header.classList.add("relative");
                Nav_Header.classList.remove("fixed-header");


                Switch_Logo(false, Logo_Black, Logo_White);

                if (!Reset_Logo_Page(Page.id)) {
                    Switch_Logo(true, Logo_Black, Logo_White);
                }

                //Verificar se contem a classe active no menu option mobile 
                if (Menu_Options_Mobile.classList.contains("active") && !Reset_Logo_Page(Page.id)) {
                    Switch_Logo(false, Logo_Black, Logo_White);
                }


            }
        })

        document.addEventListener("resize", (e) => {
            if (Value_Scroll(142)) {
                Nav_Header.classList.remove("relative");
                Nav_Header.classList.add("fixed-header");


                Switch_Logo(true, Logo_Black, Logo_White);
            }
            else {
                Nav_Header.classList.add("relative");
                Nav_Header.classList.remove("fixed-header");


                Switch_Logo(false, Logo_Black, Logo_White);


            }
        })
    }*/
}

export default Menu;