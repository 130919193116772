import { find } from "../utils/dom";
import { gsap} from "gsap/src";
import { ScrollTrigger } from "gsap/all";

const Single_Projetos = () =>{
    const Page_Single_Projetos = find("[js-page='single_projeto']");
    
    if (!Page_Single_Projetos) return;

    const box = find("[js-scroll='box']");
    const content = find("[js-scroll='content']");

    const StartEvent = box.getBoundingClientRect().top;
    const EndEvent = box.nextElementSibling.getBoundingClientRect().top;

    document.addEventListener("scroll", () =>{
        const PosY = window.scrollY;

        if(PosY => StartEvent && PosY < EndEvent && content.scrollTop <= content.scrollTopMax){
            content.scrollTop = `${PosY - StartEvent}`;
        }
    })

    /*const content = find("[js-scroll='content']");
    const box = find("[js-scroll='box']"); 

    const HeightContent = content.clientHeight;

    const StartEvent = box.getBoundingClientRect().top;
    //const EndEvent = box.nextElementSibling.getBoundingClientRect().top;

    console.log(box)

    

    document.addEventListener("scroll", (e)=>{
        const PosY = window.scrollY;
        
        if(PosY > StartEvent && PosY < EndEvent){
            content.style.top = `${PosY - StartEvent}px`;
        }
        else{
        }
    })*/
}

export default Single_Projetos;